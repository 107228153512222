import * as React from "react";
import JourneyImage from "../../components/JourneyImage";
import JourneyPageLayout, {
  CHAPTERS,
} from "../../components/JourneyPageLayout";
import balfourImage from "../../images/journey/balfour.jpg";
import ogImage from "../../images/og/journey-masterstart.jpg";

const JourneyMasterStartPage = () => {
  return (
    <JourneyPageLayout
      pageTitle="Matthew Goslett - Chief Technical Officer (CTO) at MasterStart"
      metaDescription="Matthew Goslett is the Chief Technical Officer (CTO) of Balfour Group, a technology investment group with a diverse set of ventures."
      og={{ image: ogImage }}
      chapter={CHAPTERS.MASTERSTART}
    >
      <p>
        MasterStart is a leading EdTech company based in Cape Town, South
        Africa. The company is part of the Balfour Venture Capital group and is
        backed by investors Jody Basson, Charl Norman, Jesse Hemson-Struthers,
        and Andrew Johnston.
      </p>
      <JourneyImage image={balfourImage} />
      <p>
        I joined the company in August 2017 as Chief Technology Officer (CTO)
        and worked closely with the executive team consisting of Andrew Johston
        (CEO), Katharina Scholtz (Chief Learning Officer), Chevaun Herholdt
        (Head of Marketing), and Nolen Naidoo (Head of Sales).
      </p>
      <p>
        I led the development of the client-facing MasterStart website, the
        online learning platforms, company blog, and a bespoke CRM/CMS known as
        Proteus CRM.
      </p>
      <p>
        After spending just under 2 years at MasterStart, I was promoted to CTO
        of Balfour Group, the holding company of MasterStart. Balfour is a
        technology investment group with a diverse set of ventures, ranging from
        online education to digital currencies. I spent the majority of my focus
        on MasterStart, Debtline & SaveMoney; sharing technology across these
        companies & consolidating group assets where possible.
      </p>
    </JourneyPageLayout>
  );
};

export default JourneyMasterStartPage;
