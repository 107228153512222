import { Link, navigate } from "gatsby";
import * as React from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import { JourneyChapter } from "../types";
import PageHeader from "./PageHeader";
import PageLayout, { PageLayoutProps } from "./PageLayout";
import Separator from "./Separator";

export const CHAPTERS: { [key: string]: JourneyChapter } = {
  IN_THE_BEGINNING: {
    name: "1. In the Beginning",
    url: "/journey/",
    previousChapter: () => null,
    nextChapter: () => CHAPTERS.HELLO_WORLD,
  },
  HELLO_WORLD: {
    name: "2. Hello World",
    url: "/journey/hello_world/",
    previousChapter: () => CHAPTERS.IN_THE_BEGINNING,
    nextChapter: () => CHAPTERS.ACADEMIC_LIFE,
  },
  ACADEMIC_LIFE: {
    name: "3. Academic Life",
    url: "/journey/academic_life/",
    previousChapter: () => CHAPTERS.HELLO_WORLD,
    nextChapter: () => CHAPTERS.CITYMOB,
  },
  CITYMOB: {
    name: "4. CityMob",
    url: "/journey/citymob/",
    previousChapter: () => CHAPTERS.ACADEMIC_LIFE,
    nextChapter: () => CHAPTERS.SUPERBALIST,
  },
  SUPERBALIST: {
    name: "5. Superbalist",
    url: "/journey/superbalist/",
    previousChapter: () => CHAPTERS.CITYMOB,
    nextChapter: () => CHAPTERS.MASTERSTART,
  },
  MASTERSTART: {
    name: "6. MasterStart / Balfour Group",
    url: "/journey/masterstart/",
    previousChapter: () => CHAPTERS.SUPERBALIST,
    nextChapter: () => CHAPTERS.YOCO,
  },
  YOCO: {
    name: "7. Yoco",
    url: "/journey/yoco/",
    previousChapter: () => CHAPTERS.MASTERSTART,
    nextChapter: () => null,
  },
};

const chapters = Object.values(CHAPTERS);

interface Props extends PageLayoutProps {
  chapter: JourneyChapter;
}

const JourneyPageLayout: React.FC<Props> = ({ chapter, ...props }) => {
  const previousChapter = chapter.previousChapter();
  const nextChapter = chapter.nextChapter();
  const showChapterNavigationButtons = !!(previousChapter || nextChapter);

  const onSelectMobileChapter = (event) => {
    const chapter = chapters[event.target.value];
    navigate(chapter.url);
  };

  return (
    <PageLayout {...props}>
      <Container className="mt-4">
        <Row>
          <Col
            md="4"
            lg="3"
            className="d-none d-md-block"
            style={{ borderRight: "10px solid #f8f9fa" }}
          >
            <Nav variant="pills" className="flex-column">
              {chapters.map((chapter, index) => {
                return (
                  <Nav.Item key={index}>
                    <Nav.Link
                      as={Link}
                      to={chapter.url}
                      activeClassName="active"
                    >
                      {chapter.name}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
          <Col md="8" lg="9">
            <PageHeader header="My Journey" />
            <div className="d-md-none">
              <select
                className="form-control mb-2"
                onChange={onSelectMobileChapter}
              >
                {chapters.map((chapter, index) => {
                  return (
                    <option key={index} value={index}>
                      {chapter.name}
                    </option>
                  );
                })}
              </select>
              <hr />
            </div>
            <h3>{chapter.name}</h3>
            <Separator small />
            {props.children}
            {showChapterNavigationButtons && (
              <>
                <hr />
                <div className="clearfix">
                  {previousChapter && (
                    <div className="float-left">
                      <Button
                        as={Link}
                        to={previousChapter.url}
                        variant="secondary"
                        size="lg"
                      >
                        &larr;{" "}
                        <span className="d-none d-md-inline">
                          {previousChapter.name}
                        </span>
                      </Button>
                    </div>
                  )}
                  {nextChapter && (
                    <div className="float-right">
                      <Button
                        as={Link}
                        to={nextChapter.url}
                        variant="secondary"
                        size="lg"
                      >
                        <span className="d-none d-md-inline">
                          {nextChapter.name}
                        </span>{" "}
                        &rarr;
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};

export default JourneyPageLayout;
