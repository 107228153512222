import * as React from "react";
import { Col, Row } from "react-bootstrap";

type Props = {
  image: any;
};

const JourneyImage: React.FC<Props> = ({ image }) => {
  const images = Array.isArray(image) ? image : [image];
  const count = images.length;

  if (count === 1) {
    return (
      <Row>
        <Col md="8" className="mb-3">
          <img
            src={images[0]}
            alt=""
            className="img-border rounded img-fluid"
          />
        </Col>
      </Row>
    );
  } else if (count === 2) {
    return (
      <Row>
        <Col md="6" className="mb-3">
          <img
            src={images[0]}
            alt=""
            className="img-border rounded img-fluid"
          />
        </Col>
        <Col md="6" className="mb-3">
          <img
            src={images[1]}
            alt=""
            className="img-border rounded img-fluid"
          />
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default JourneyImage;
